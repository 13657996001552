import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import Smart1 from "../../../images/uploads/smart/smart1.png"
import Smart2 from "../../../images/uploads/smart/smart2.png"
import Smalight from "../../../images/uploads/smart/smalight.png"
import Smadark from "../../../images/uploads/smart/smadark.png"
import Smart4 from "../../../images/uploads/smart/smart4.png"
import Smart5 from "../../../images/uploads/smart/smart5.png"
import Smart6 from "../../../images/uploads/smart/smart6.png"
import Smart7 from "../../../images/uploads/smart/smart7.png"
import Sms1 from "../../../images/uploads/smart/sms1.png"
import Sms2 from "../../../images/uploads/smart/sms2.png"
import Sms3 from "../../../images/uploads/smart/sms3.png"
import Sms4 from "../../../images/uploads/smart/sms4.png"
import Sms5 from "../../../images/uploads/smart/sms5.png"
import Sms6 from "../../../images/uploads/smart/sms6.png"
import Sms7 from "../../../images/uploads/smart/sms7.png"
import Sms8 from "../../../images/uploads/smart/sms8.png"
import Sms9 from "../../../images/uploads/smart/sms9.png"
import Sms10 from "../../../images/uploads/smart/sms10.png"

import addcs1 from "../../../images/uploads/decodeup-bundle-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-callaed-additional-case-study.png"

const SmartNotes = props => {
  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang
  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    // contain: true,
    // wrapAround: true,
    // initialIndex: 2,
    // pageDots: false,
    // autoPlay: false,
    // prevNextButtons: false,
    // adaptiveHeight: true,
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "Smartnotes"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full  xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-3-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  A Secret Note is Something to Discover
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Smart Notes & Puzzles Platform
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  The goal of this site is to engage with new information and to
                  create new, interesting entities in the process. We began by
                  talking with the client about the overall purpose of the
                  website, which was both for education and for promoting the
                  Snotes company in a fun, original way.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Matin T
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      USA
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, SEO, Maintenance and Support, UI/UX Design,
                      Product management, Hosting and Maintenance
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Wordpress, JS
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Education
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <img
            className="mx-auto"
            src={Smart1}
            alt="SmartNotes Decodeup Technologies"
          />
        </section>

        {/* Engaging methods for learning new Information */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-smt mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-2 md:gap-8 lg:gap-16 md:items-center justify-center">
              <div className="w-full sm:w-1/3 lg:w-2/5 max-w-xs">
                <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Engaging methods for learning new Information
                </h3>
              </div>
              <div className="w-full sm:w-2/3 lg:w-3/5 ">
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Our client had a truly unique idea that they shared with us
                  during our initial meetings. As always, we requested the
                  technical and aesthetic requirements for the project.They
                  chose our team for our track record of creating easy,
                  interactive websites that are beautiful to view.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Share messages with clue cards */}
        <section className="mb-10 md:mb-16 xl:mb-20">
          <div className="container container-smt mx-auto">
            <div className="px-4 pt-6 pb-16 xl:px-1-22 xl:pt-1-10 xl:pb-4-00 mx-auto w-full max-w-9-76 text-center smt-yellow xl:rounded-1/2">
              <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-dark-blue ">
                Share messages with clue cards
              </h3>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue    font-worksans-normal">
                Some of the elements we used in this website needed to be
                developed custom for the client. For their clue card features we
                would need to craft an algorithm that performed tasks unique to
                S-note. The main focus was secret card, building messages that
                users can send and arrange in secret without public visibility.
              </p>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-dark-blue    font-worksans-normal">
                From this, our team of engineers decoded the messages in various
                ways, which is what made the website interactive and
                interesting. At the same time, we didn't want to detract from
                the simplicity and ease of use of the site.
              </p>
            </div>
            <div className="-mt-60 xl:-mt-3_0">
              <img
                className="mx-auto w-full h-full object-cover"
                src={Smart2}
                alt="SmartNotes Decodeup Technologies"
              />
            </div>
            <div className="mx-auto w-full max-w-9-76 text-center">
              <h3 class="mt-2 mb-2 lg:mb-8 xl:mt-10 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary ">
                The S-Note website was not going to be easy--in fact, it
                included some complex components. For this reason, we were eager
                to get started crafting solutions for this client and pulling
                together a beautiful site.
              </h3>
            </div>
          </div>
        </section>

        {/* Challenges section */}
        <section className="mb-10 md:mb-16 xl:mb-20">
          <div className="container container-smt mx-auto">
            <div className="mb-8 md:mb-12 xl:mb-16 w-auto text-center">
              <img
                className="mx-auto sma-light"
                src={Smalight}
                alt="SmartNotes Decodeup Technologies"
              />
              <img
                className="mx-auto sma-dark"
                src={Smadark}
                alt="SmartNotes Decodeup Technologies"
              />
            </div>

            <div className="mb-3 md:pb-4 xl:mb-10 text-center">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Challenges
              </h2>
            </div>
            <div className="mb-4 lg:mb-8 xl:mb-10 mx-auto w-full max-w-9-76 text-center">
              <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary ">
                As we reviewed notes from our initial meeting, our team
                identified a list of challenges and potential setbacks:
              </h3>
            </div>
            <div className="flex flex-wrap md:flex-nowrap gap-y-5 md:gap-10 lg:gap-20 font-worksans-normal text-p4 lg:text-p3 xl:text-p2 text-term-primary sma-challnges">
              <ul className="w-full sm:w-1/2 flex flex-col gap-5 list-none text-term-primary font-worksans-normal text-p3 md:text-p2">
                <li className="ml-6 lg:ml-10 relative">
                  Creating a custom algorithm so users could write encrypted
                  messages.
                </li>
                <li className="ml-6 lg:ml-10 relative">
                  Four lines required encryption to generate a note
                </li>
                <li className="ml-6 lg:ml-10 relative">
                  Algorithms needs to format the font, color, width, height and
                  more.
                </li>
                <li className="ml-6 lg:ml-10 relative">
                  The site needed sharing capabilities with friends
                </li>
                <li className="ml-6 lg:ml-10 relative">
                  Providing various designs for the clue card
                </li>
              </ul>
              <ul className="w-full sm:w-1/2 flex flex-col gap-5 list-none">
                <li className="ml-6 lg:ml-10 relative">
                  Create an attractive site that users find helpful.
                </li>
                <li className="ml-6 lg:ml-10 relative">
                  We knew we had to implement a feature that would allow
                  teachers to pose questions via the S-notes. Students could
                  then discover the questions hidden in the website and vice
                  versa, by finding clues hidden throughout the quizzer.
                </li>
                <li className="ml-6 lg:ml-10 relative">
                  All of this needed to be accessible to all users. We had to
                  find a way for users to also request permissions to make their
                  own clue cards and notifications to let them know if their
                  request was accepted.
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Challenges banner sections */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-smt mx-auto">
            <div className="flex flex-wrap md:flex-nowrap gap-6">
              <div className="w-full md:w-2/5 max-w-480 mx-auto">
                <img
                  className="mb-6 mx-auto"
                  src={Smart4}
                  alt="SmartNotes Decodeup Technologies"
                />
                <img
                  className="mx-auto"
                  src={Smart5}
                  alt="SmartNotes Decodeup Technologies"
                />
              </div>
              <div className="pt-6 lg:pt-10 xl:pt-20 md:w-3/5 flex flex-col justify-between smt-yellow">
                <h2 className="text-h0 smt-note-text text-center mx-auto text-dark-blue">
                  Send a romantic message to a loved one in the form of a snot
                </h2>
                <img
                  className="mx-auto"
                  src={Smart6}
                  alt="SmartNotes Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Solution sec */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="w-full mb-10 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-center">
                Solutions
              </h2>
            </div>
            <div className="flex items-top flex-wrap lg:flex-nowrap gap-6 lg:gap-10 justify-between items-center">
              <div className="w-full lg:w-1/3 ">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  A simplified workflow
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We met with our client and presented a developed outline of
                  the project that made their concept, desired design elements
                  and backend management simpler, more refined and completely
                  intuitive with our advanced UI/UX.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We found various solutions for this client by leaning into our
                  expertise and listening intently to their website needs. The
                  result was a reliable, secure website that brought all of
                  their demands together.
                </p>
              </div>
              <div className="mx-auto w-full sm:w-1/3 max-w-3-52">
                <img
                  className="mx-auto w-full object-cover"
                  src={Smart7}
                  alt="SmartNotes Decodeup Technologies"
                />
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/3 ">
                <div className="mb-10 lg:mb-16">
                  <h3 class="mt-4 lg:mt-0 mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    A better end user experience
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    The user dashboard was crafted with S-Note in mind to give
                    end users a variety of options when creating their own
                    notes.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    We also instituted a share function so users can share their
                    notes with friends, ensuring that the website performs
                    exactly as the client intended it to.
                  </p>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Lastly, Decode Up redesigned the website to look like a
                    trustworthy, sleek brand that users will want to visit again
                    and again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* typeface */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="mb-8 lg:mb-12 flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16">
              <div className="w-full sm:w-2/5 max-w-480">
                <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  Typeface & <br className="hidden sm:block" />
                  Colors
                </h2>
              </div>
              <div className="w-ull sm:w-3/5 smart-typo text-term-primary">
                <h4 className="mb-2 lg:mb-8 text-term-primary">Open Sans</h4>
                <h5 className="mb-2 lg:mb-6 text-term-primary opacity-70">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h6 className="text-term-primary opacity-70">
                  abcdefghijklmnopqrstuvwxyz
                </h6>
              </div>
            </div>
            <div className="mb-12 flex flex-wrap sm:flex-nowrap smart-color">
              <div className="p-4 lg:p-8 w-1/2 sm:w-1/4 smart-co-1">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                  #F26529
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 sm:w-1/4 smart-co-2">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                  #299E90
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 sm:w-1/4 smart-co-3">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                  #BA079B
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 sm:w-1/4 smart-co-4">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                  #00AADD
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Result */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="md:w-5/65">
              <h2 className="mb-4 lg:mb-6-6 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal ">
                Our efforts resulted in an aesthetically beautiful and intuitive
                website that performs every function it needs to for its target
                audience. It's also easily adaptable for future updates.
              </p>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                The client adored the new site and that's always a sign that
                we've accomplished our goals. Websites are never truly complete,
                and like a home needs constant maintenance and care. We're happy
                to provide that for all of our customers
              </p>
              <div className="mt-6 w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  When you work with DecodeUp Technologies, you work with a team
                  that's committed to your satisfaction every single day, every
                  single time.
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-smt mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms1}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms2}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms3}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms4}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms5}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms6}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms7}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms8}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms9}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Sms10}
                    alt="SmartNotes Decodeup Technologies"
                  />
                  {/* {info.screenshots_for_carousel.map(
                        ({ image: img }, ss) => (
                          <img
                            key={ss}
                            src={getSrc(img)}
                            className="cs-carousel-image"
                            alt="decodeup case study"
                          />
                        )
                      )} */}
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}
        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/travel-hospitality-deals-platform"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Travel & Hospitality Deals Platform
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs1}
                      alt="Washington In Your Pocker Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default SmartNotes

export const pageQuery = graphql`
query smartnotespagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/smart-notes-and-puzzles-platform.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;
